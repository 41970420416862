<template>
  <div>
    <v-card
      class="mx-auto"
      max-width="434"
      tile
    >
      <v-img
        height="100%"
        src="../assets/bg-profile.jpg"
      >
        <v-row
          align="end"
          class="fill-height"
        >
          <v-col
            align-self="start"
            class="pa-0"
            cols="12"
          >
            <v-avatar
              class="profile"
              color="grey"
              size="164"
              tile
            >
              <v-img src="../assets/avatar.jpg"></v-img>
            </v-avatar>
          </v-col>
          <v-col class="py-0">
            <v-list-item
              color="rgba(0, 0, 0, .4)"
              dark
            >
              <v-list-item-content class="mb-5">
                <v-list-item-title class="text-h6">David Tavares</v-list-item-title>
                <v-list-item-subtitle>Building solutions with software</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-img>
    </v-card>

    <v-card
      class="mx-auto"
      max-width="434"
      tile
    >
      <v-row justify="center" align="center">
        <v-col align="center">
          <v-btn
              icon
          href="mailto:eu@davidtavares.com.br">
            <v-icon>mdi-at</v-icon>
          </v-btn>
          <v-btn
              icon href="https://www.linkedin.com/in/davidwtavares/" target="_blank">
            <v-icon>mdi-linkedin</v-icon>
          </v-btn>
          <v-btn
              icon href="https://instagram.com/odavidtavares" target="_blank">
            <v-icon>mdi-instagram</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'ProfileCard',
  created() {
    document.title = "David Tavares - Software Development"
  }
}
</script>

